<template>
  <div>
    <filter-section :cols="[3,3]">
      <search-filter
        slot="col_0"
        v-model="filters"
      />
    </filter-section>

    <v-card>
      <v-card-text class="d-flex justify-space-between">
        <div>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="paginate"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiRefresh }}
            </v-icon>
            <span>
              {{ $t('common.list.refresh') }}
            </span>
          </v-btn>
        </div>

        <div>
          <create-dialog
            v-if="$can('activity:create', 'activity_management')"
            @created="paginate"
          />
        </div>
      </v-card-text>

      <activity-table
        ref="tableRef"
        :table-list="tableList"
      />
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { FilterSection, SearchFilter } from '@/components'
import { useTableList } from '@/composables'
import { mdiRefresh } from '@mdi/js'
import ActivityTable from '../../components/activity/ActivityTable.vue'
import CreateDialog from '../../components/activity/CreateDialog.vue'
import { activityService } from '../../services'

export default {
  components: {
    FilterSection,
    SearchFilter,
    ActivityTable,
    CreateDialog,
  },
  setup() {
    const filters = ref({
      search: null,
    })

    const tableList = useTableList(activityService, filters)

    const {
      loading,
      tableRef,
      paginate,
    } = tableList

    return {
      filters,

      loading,
      tableRef,
      paginate,
      tableList,

      icons: {
        mdiRefresh,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
