<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <!-- nicename -->
      <template #[`item.nicename`]="{ item }">
        {{ item.nicename }}
        <div>
          <small>{{ item.slug }}</small>
        </div>
      </template>

      <!-- schedules_at -->
      <template #[`item.schedules_at`]="{ item }">
        <span class="text-no-wrap">{{ formatDate(item.schedules_at) }}</span>
      </template>

      <!-- invitations_count -->
      <template #[`item.invitations_count`]="{ item }">
        <b>{{ item.invitations_count }}</b>
      </template>

      <!-- accepted_count -->
      <template #[`item.accepted_count`]="{ item }">
        <b>{{ item.accepted_count }}</b> /{{ item.rule.max_participant }}
      </template>

      <!-- merchant -->
      <template #[`item.merchant_id`]="{ item }">
        <router-link :to="{ name: 'merchant-overview', params: { id : item.merchant_id }}">
          {{ item.merchant ? t(item.merchant.name) : 'N/A' }}
        </router-link>
      </template>

      <!-- user -->
      <template #[`item.user_id`]="{ item }">
        {{ item.user ? item.user.email : 'N/A' }}
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{ item }">
        <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="loadResource(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <view-participants-dialog
                v-if="resource"
                :resource="resource"
                @updated="paginate"
              />
            </v-list>

            <v-list>
              <delete-dialog
                v-if="resource"
                :resource="resource"
                @updated="paginate"
              />
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDelete, mdiDotsVertical, mdiEye } from '@mdi/js'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import DeleteDialog from './DeleteDialog.vue'
import ViewParticipantsDialog from './ViewParticipantsDialog.vue'

dayjs.extend(utc)

export default {
  components: {
    DeleteDialog,
    ViewParticipantsDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops, loadResource, resource,
    } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'nicename', value: 'nicename' },
      { text: 'schedules_at', value: 'schedules_at' },
      { text: 'Invited', value: 'invitations_count' },
      { text: 'Participant', value: 'accepted_count' },

      { text: 'merchant', value: 'merchant_id' },
      { text: 'user', value: 'user_id' },
      { text: 'Date', value: 'created_at' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      t,
      dayjs,
      formatDate,

      resource,
      loadResource,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
        mdiDelete,
        mdiEye,
      },
    }
  },
}
</script>
