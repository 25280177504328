var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-section text-no-wrap",attrs:{"headers":_vm.columns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.itemsTotal,"loading":_vm.loading,"header-props":_vm.headerprops},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.nicename",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nicename)+" "),_c('div',[_c('small',[_vm._v(_vm._s(item.slug))])])]}},{key:"item.schedules_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.schedules_at)))])]}},{key:"item.invitations_count",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.invitations_count))])]}},{key:"item.accepted_count",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.accepted_count))]),_vm._v(" /"+_vm._s(item.rule.max_participant)+" ")]}},{key:"item.merchant_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'merchant-overview', params: { id : item.merchant_id }}}},[_vm._v(" "+_vm._s(item.merchant ? _vm.t(item.merchant.name) : 'N/A')+" ")])]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.email : 'N/A')+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.loadResource(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[(_vm.resource)?_c('view-participants-dialog',{attrs:{"resource":_vm.resource},on:{"updated":_vm.paginate}}):_vm._e()],1),_c('v-list',[(_vm.resource)?_c('delete-dialog',{attrs:{"resource":_vm.resource},on:{"updated":_vm.paginate}}):_vm._e()],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }