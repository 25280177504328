<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <!-- Magic spell: example resource -> [[your resource name]], remove all comments, unused variables, imports -->

    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Resource
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.nicename"
              label="Name"
              outlined
              :rules="[validators.required]"
              :disabled="disabledFields.includes('nicename')"
            />

            <v-text-field
              v-model="form.slug"
              label="Slug"
              outlined
              :rules="[validators.required]"
              :disabled="disabledFields.includes('slug')"
              @blur="form.slug = useSlugify(form.slug)"
            />
            <a @click="useAutoSlug">auto slug</a>
          </v-col>

          <v-col cols="6">
            <search-user
              :query="resource && resource.user ? resource.user.email : ''"
              @options="options => userOptions = options"
            ></search-user>
          </v-col>

          <v-col cols="6">
            <v-autocomplete
              v-model="form.user_id"
              :items="userOptions"
              item-text="label"
              item-value="value"
              placeholder="User"
              clearable
              :disabled="disabledFields.includes('user_id')"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="form.rule_id"
              :items="activityRuleOptions"
              item-text="label"
              item-value="value"
              placeholder="Rule"
              :disabled="disabledFields.includes('rule_id')"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <date-time-picker v-model="form.schedules_at" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { now } from '@/@core/utils/filter'
import { DateTimePicker, SearchUser } from '@/components'
import {
  useActivityRule, useMerchant,
  useRandomString,
  useSlugify,
} from '@/composables'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: {
    SearchUser,
    DateTimePicker,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    resource: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false)

    const useFieldValue = (fieldName, defaultValue) => (props.resource ? props.resource[fieldName] : defaultValue)

    const defaultFormValue = ({
      nicename: useFieldValue('nicename', 'Example Default Name'),
      slug: useFieldValue('slug', ''),
      user_id: useFieldValue('user_id', ''),
      sku_id: useFieldValue('sku_id', ''),
      rule_id: useFieldValue('rule_id', ''),
      schedules_at: useFieldValue('schedules_at', now()),
    })

    defaultFormValue.schedules_at = now(defaultFormValue.schedules_at).toString()

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const { fetchMerchants } = useMerchant()
    fetchMerchants({ take: 999 })
    const { activityRuleOptions, fetchActivityRules } = useActivityRule()
    fetchActivityRules({ take: 999 })
    const userOptions = ref([])

    const onSubmit = () => {
      if (formRef.value.validate()) {
        console.log(form.value)

        emit('submit', form.value)
      }
    }

    const onClose = () => {
      if (isEditing.value) {
        formRef.value.resetValidation()
      } else {
        formRef.value.reset()

        Object.assign(form.value, defaultFormValue)
      }

      emit('close')
    }

    const useAutoSlug = () => {
      form.value.slug = useSlugify(`${form.value.nicename}-${useRandomString(4)}`)
    }

    return {
      isEditing,

      form,
      formRef,

      activityRuleOptions,
      userOptions,

      useAutoSlug,

      // Rules
      validators: {
        required,
      },

      t,
      onSubmit,
      onClose,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
