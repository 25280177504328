<template>
  <div v-if="activity">
    <h2>{{ activity.nicename }}</h2>

    <h3>Participants ({{ activity.accepted_count }}):</h3>

    <div
      v-for="(invitation, index) in activity.invitations"
      :key="invitation.uuid"
      class="mb-2"
    >
      #{{ index + 1 }} <v-chip class="mr-2 ml-2">
        {{ invitation.status }}
      </v-chip> {{ invitation.participant_data }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activity: {
      type: Object,
      default: null,
    },
  },
}
</script>
