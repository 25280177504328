<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-list-item
        link
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-title>
          <v-icon
            size="17"
            class="me-3"
          >
            {{ icons.mdiEye }}
          </v-icon>
          <span>View Participants</span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <v-card>
      <v-card-text>
        <ActivityDetails :activity="resource">
        </ActivityDetails>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiEye } from '@mdi/js'
import { ref } from '@vue/composition-api'
import ActivityDetails from './ActivityDetails.vue'

export default {
  components: {
    ActivityDetails,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const isDialogVisible = ref(false)
    const loading = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    return {
      formRef,
      isDialogVisible,
      loading,

      onClose,

      icons: {
        mdiEye,
      },
    }
  },
}
</script>
